import React from 'react';
import {
  Twitter as TwitterIcon,
  Home as HomeIcon,
  Search as SearchIcon,
  NotificationsNone as NotificationsNoneIcon,
  BookmarkBorder as BookmarkBorderIcon,
  ListAlt as ListAltIcon,
  PermIdentity as PermIdentityIcon,
  MoreHoriz as MoreHorizIcon,
  MailOutline as MailOutlineIcon,
  Create as CreateIcon
} from '@material-ui/icons';
import { Button, useMediaQuery, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mainColor, mainBackgroundColor, desktopScreenMinWidth } from '../../constants/css';
import { SidebarOption } from './SidebarOption';

interface ISidebarStylesProp {
  isDesktopScreen: boolean;
}

const useStyles = makeStyles({
  sidebar: (props: ISidebarStylesProp) => ({
    borderRight: `solid 1px ${mainBackgroundColor}`,
    flex: 0.2,
    alignItems: 'flex-start',
    marginTop: '20px',
    paddingLeft: props.isDesktopScreen ? '20px' : 0,
    paddingRight: props.isDesktopScreen ? '20px' : 0
  }),
  sidebar__tweetButton: {
    backgroundColor: `${mainColor} !important`,
    border: 'none !important',
    color: 'white !important',
    fontWeight: '900 !important' as any,
    textTransform: 'inherit !important' as any,
    borderRadius: '30px !important',
    height: '50px !important',
    marginTop: '20px !important'
  },
  sidebar__tweetMobileButton: {
    backgroundColor: `${mainColor} !important`,
    border: 'none !important',
    color: 'white !important',
    fontWeight: '900 !important' as any,
    textTransform: 'inherit !important' as any,
    marginTop: '20px !important',
    marginLeft: '5px !important'
  },
  sidebar__twitterIcon: {
    color: mainColor,
    fontSize: '30px !important',
    marginLeft: '20px',
    marginBottom: '20px'
  }
});

export function SidebarTweetButton() {
  const isDesktopScreen = useMediaQuery(`(min-width:${desktopScreenMinWidth})`);
  const cssClasses = useStyles({ isDesktopScreen });

  if (isDesktopScreen) {
    return (
      <Button
        variant="outlined"
        className={cssClasses.sidebar__tweetButton}
        fullWidth
      >
        Tweet
      </Button>
    );
  }

  return (
    <IconButton className={cssClasses.sidebar__tweetMobileButton}>
      <CreateIcon />
    </IconButton>
  );
}

export function Sidebar() {
  const isDesktopScreen = useMediaQuery(`(min-width:${desktopScreenMinWidth})`);
  const cssClasses = useStyles({ isDesktopScreen });

  return (
    <div className={cssClasses.sidebar}>
      <TwitterIcon className={cssClasses.sidebar__twitterIcon} />
      <SidebarOption isActive Icon={HomeIcon} text="Home" />
      <SidebarOption Icon={SearchIcon} text="Explore" />
      <SidebarOption Icon={NotificationsNoneIcon} text="Notifications" />
      <SidebarOption Icon={MailOutlineIcon} text="Messages" />
      <SidebarOption Icon={BookmarkBorderIcon} text="Bookmarks" />
      <SidebarOption Icon={ListAltIcon} text="Lists" />
      <SidebarOption Icon={PermIdentityIcon} text="Profile" />
      <SidebarOption Icon={MoreHorizIcon} text="More" />
      <SidebarTweetButton />
    </div>
  );
}